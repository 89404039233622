import { ErrorBoundary, Loading } from "@react-force/core"
import React, { Suspense } from "react"
import { RouterContext, RouterView } from "mobx-state-router"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { RootStoreContext } from "./contexts"
import { initApp } from "./initApp"
import { viewMap } from "./stores/viewMap"

const rootStore = initApp()

function App() {
    const {routerStore} = rootStore
    const darkTheme = createMuiTheme({
        palette: {
            type: "dark",
        },
    })

    return (
        <ThemeProvider theme={darkTheme}>
            <ErrorBoundary>
                <Suspense fallback={<Loading/>}>
                    <RootStoreContext.Provider value={rootStore}>
                        <RouterContext.Provider value={routerStore}>
                            <CssBaseline/>
                            <RouterView viewMap={viewMap}/>
                        </RouterContext.Provider>
                    </RootStoreContext.Provider>
                </Suspense>
            </ErrorBoundary>
        </ThemeProvider>
    )
}

export default App
