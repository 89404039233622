import * as React from "react"
import { motion } from "framer-motion"
import { useSwipeable } from "react-swipeable"

export interface Props {
    patch: string
    viewBox: string
    width: number
    height: number
    url: string
    onNext?: any
    image: any
    controls: any
    controls2: any
    controls3: any
    onPrev: any
}

function SvgComponent(props: Props) {
    const handleNext = () => {
        props.onNext()
    }
    const handlePrev = () => {
        props.onPrev()
    }

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
    })

    return (
        <motion.div
            animate={props.controls3}
            {...handlers}
        >
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="viewport"
                version="1.1"
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                style={{
                    display: "block",
                    left: "0%",
                    top: "0px",
                    maxHeight: "calc(100vh - 56px)",
                    width: "auto",
                    maxWidth: "100vW",
                    margin: "0 auto"
                }}
                viewBox={props.viewBox}
                animate={props.controls}
                onClick={handleNext}
            >
                <image
                    width={props.width}
                    height={props.height}
                    clipPath="url(#clip)"
                    xlinkHref={props.image ? props.image.src : ""}
                ></image>
                <defs>
                    <clipPath id="clip">
                        <motion.polygon id="rect" points={props.patch} animate={props.controls2}></motion.polygon>
                    </clipPath>
                </defs>
            </motion.svg>
        </motion.div>
    )
}

export default SvgComponent
