import { GridBooks, GridBooksWrapper } from "../../components/GridBooks"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import IconButton from "@material-ui/core/IconButton"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import React from "react"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { observer } from "mobx-react"
import { red } from "@material-ui/core/colors"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            alignItems: "flex-start",
            padding: theme.spacing(1),
        },
        inline: {
            display: "inline",
        },
        media: {
            height: 140,
        },
        image: {
            maxWidth: theme.spacing(8),
            display: "block",
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            backgroundColor: red[500],
        },
        rootControls: {
            padding: theme.spacing(0),
        },
        crumbs: {
            padding: theme.spacing(1),
        },
        link: {
            display: "flex",
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        img: {
            maxWidth: theme.spacing(3),
        },
        caption: {
            padding: "0 8px",
            margin: 0,
        }
    }),
)

function SeriesPage() {
    const {seriesStore, routerStore} = useRootStore()
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    if (!seriesStore.series) {
        return null
    }

    const handleClick = (e, id) => {
        e.preventDefault()
        routerStore.goTo("series", {
            params: {
                id: id
            }
        })
    }
    const handleToVendor = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id) => {
        event.preventDefault()
        routerStore.goTo("publisher", {
            params: {
                id: id
            }
        })
    }

    const handleBookClick = (id) => {
        routerStore.goTo("comix", {
            params: {
                comixCode: id
            }
        })
    }
    const handleClickHome = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        routerStore.goTo("home")
    }
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const children = seriesStore.series.books.length > 0 ? seriesStore.series.children.slice(0, 3) : seriesStore.series.children

    return (
        <React.Fragment>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" className={classes.crumbs}>
                <Link color="inherit" href="#" className={classes.link} onClick={handleClickHome}>
                    <Typography variant="caption" color={"textSecondary"}>online</Typography>
                    <Typography variant="button" color={"textPrimary"}>Comix</Typography>
                </Link>
                <Link
                    color="inherit"
                    href="#"
                    onClick={(event) => handleToVendor(event, seriesStore.series.model.vendorId)}
                >
                    {seriesStore.series.model.vendor}
                </Link>
                {seriesStore.series.parents?.map((p, j) => (
                    <Link color="inherit" href="#" onClick={(e) => handleClick(e, p.id)} key={j}>
                        {p.name}
                    </Link>
                ))}
            </Breadcrumbs>
            <Card>
                <CardHeader
                    className={classes.root}
                    title={seriesStore.series.model.name}
                    subheader={seriesStore.series.model.vendor}/>
                {seriesStore.series.model.description &&
                    <div className={classes.crumbs}>
                        <Typography variant="caption" display="block" gutterBottom color="textSecondary">
                            {seriesStore.series.model.description}
                        </Typography>
                    </div>
                }
            </Card>
            {seriesStore.series.children.length > 0 && <React.Fragment>
                <div className={classes.caption}>
                    <Typography variant="caption" display="block" gutterBottom color="textSecondary">
                        Включает серии:
                    </Typography>
                </div>
                <List className={classes.root}>
                    {children.map((series, i) => (
                        <React.Fragment key={i}>
                            <ListItem>
                                <ListItemIcon>
                                    {series.cover && series.cover !== "" &&
                                        <img src={series.cover} alt={""} className={classes.img}/>
                                    }
                                    {(!series.cover || series.cover === "") &&
                                        <LibraryBooksIcon color="disabled"/>
                                    }
                                </ListItemIcon>
                                <ListItemText secondary={series.name} onClick={(e) => handleClick(e, series.id)}/>
                            </ListItem>
                            <Divider component="li"/>
                        </React.Fragment>
                    ))}
                    {seriesStore.series.books.length > 0 && seriesStore.series.children.length > 3 && <React.Fragment>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {seriesStore.series.children.slice(3).map((series, i) => (
                                <React.Fragment key={i}>
                                    <ListItem>
                                        <ListItemIcon>
                                            {series.cover && series.cover !== "" &&
                                                <img src={series.cover} alt={""} className={classes.img}/>
                                            }
                                            {(!series.cover || series.cover === "") &&
                                                <LibraryBooksIcon color="disabled"/>
                                            }
                                        </ListItemIcon>
                                        <ListItemText secondary={series.name} onClick={(e) => handleClick(e, series.id)}/>
                                    </ListItem>
                                    <Divider component="li"/>
                                </React.Fragment>
                            ))}
                        </Collapse>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </React.Fragment>}
                </List>
            </React.Fragment>}
            {seriesStore.series.books &&
                <GridBooksWrapper>
                    {seriesStore.series.books.map((book, i) => (
                        <GridBooks
                            key={i}
                            title={"#" + book.number}
                            image={book.cover}
                            onClick={() => handleBookClick(book.id)}
                        />
                    ))}
                </GridBooksWrapper>
            }
        </React.Fragment>
    )
}

export default observer(SeriesPage)