import API from "../components/API"

export interface Parents {
    id: number
    name: string
}
export interface Children {
    id: number
    name: string
    level: number
    cover: string
}
export interface Books {
    id: number
    name: string
    cover: string
    number: number
    other: string
}
export interface Model {
    name: string
    vendor: string
    vendorId: number
    description: string
}

export interface Series {
    parents: Parents[]
    children: Children[]
    books: Books[]
    model: Model
}

export async function GetSeries(id: string) {

    return await API.get("/get-series", {
        params: {
            id: id,
        }
    })
}
