import API from "../components/API"

export interface Block {
    patch: string
    patchNext: string
    patchPrev: string
    viewBox: string
    viewBoxNext: string
    viewBoxPrev: string
    nextPage: boolean
    prevPage: boolean
    isFirst: boolean
    isLast: boolean

}
export interface ComixData {
    width: number
    height: number
    img: string
    p: Block[]
}
export async function GetComix(id:string) {

    return await API.get("/get-comix", {
        params: {
            id: id,
        }
    })
}
