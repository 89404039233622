import { GridBooks, GridBooksWrapper } from "../../components/GridBooks"
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        crumbs: {
            padding: theme.spacing(1),
        },
        link: {
            display: "flex",
        },
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: 8,
            marginBottom: 5,
        },
        header: {
            padding: 0,
        },
        caption: {
            padding: "0 8px",
            margin: 0,
        }
    }),
)

function HomePage() {
    const {lastStore, routerStore} = useRootStore()
    const classes = useStyles()
    const handleClickHome = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        routerStore.goTo("home")
    }

    const handleClick = () => {
        routerStore.goTo("publishers")
    }

    const handleBookClick = (id) => {
        routerStore.goTo("comix", {
            params: {
                comixCode: id
            }
        })
    }

    return (
        <React.Fragment>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" className={classes.crumbs}>
                <Link color="inherit" href="#" className={classes.link} onClick={handleClickHome}>
                    <Typography variant="caption" color={"textSecondary"}>online</Typography>
                    <Typography variant="button" color={"textPrimary"}>Comix</Typography>
                </Link>
            </Breadcrumbs>
            <Grid container spacing={0}>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={6}>
                    <a href={"https://boosty.to/online-comix"} target={"_blank"}><img src={"./boosty.svg"} style={{height: 48, display: "inline-block"}}/></a>
                </Grid>
            </Grid>

            <div className={classes.caption}>
                <Typography variant="caption" display="block" gutterBottom color="textSecondary">
                    Поиск комиксов:
                </Typography>
            </div>
            <Card className={classes.root} onClick={handleClick}>
                <CardHeader
                    className={classes.header}
                    title={"По издательствам"}/>
            </Card>

            {lastStore.books && <React.Fragment>
                <div className={classes.caption}>
                    <Typography variant="caption" display="block" gutterBottom color="textSecondary">
                        Новое на сайте:
                    </Typography>
                </div>
                <GridBooksWrapper>
                    {lastStore.books.map((book, i) => (
                        <GridBooks
                            key={i}
                            title={book.serial + " #" + book.number}
                            image={book.cover}
                            onClick={() => handleBookClick(book.id)}
                        />
                    ))}
                </GridBooksWrapper>
            </React.Fragment>
            }

        </React.Fragment>
    )
}

export default observer(HomePage)
