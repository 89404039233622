import React, { ReactNode } from "react"
import { makeStyles } from "@material-ui/core/styles"

type GridBooksProps = {
    image: string,
    title: any,
    onClick: any,
}

const itemStyles = makeStyles({
    root: {
        maxWidth: "33%",
        width: 130,
        flexShrink: 0,
        overflow: "hidden",
        padding: 5,
        position: "relative",
        cursor: "pointer",
        "&>img": {
            maxWidth: "100%",
            maxHeight: 200,
            display: "block",
            width: "100%",
            height: "100%",
        },
        "&>div": {
            left: 0,
            bottom: 0,
            minHeight: 32,
            padding: 5,
            width: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.5)",
            textAlign: "center",
        },
    },
})

export function GridBooks(props: GridBooksProps) {
    const classes = itemStyles()
    return (
        <div className={classes.root}>
            <img src={props.image} alt={props.title} onClick={props.onClick}/>
            <div onClick={props.onClick}>{props.title}</div>
        </div>
    )
}

type GridbooksPropsWrapper = {
    children?: ReactNode | ReactNode[]
}

const wrapperStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "auto",
        padding: 5,
    },
})

export function GridBooksWrapper(props: GridbooksPropsWrapper) {
    const classes = wrapperStyles()
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}
