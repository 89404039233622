import API from "../components/API"

export interface Vendor {
    id: number
    name: string
    alias: string
}
export interface Series {
    id: string
    name: string
    cover: string
}
export interface Publisher {
    vendor: Vendor
    series: Series[]
}

export async function GetPublisher(id: string) {

    return await API.get("/get-publisher", {
        params: {
            id: id,
        }
    })
}
