// Describes the supported routes, their parameters and event handlers

import {
    RouterState,
    RouterStore
} from "mobx-state-router"
import { RootStore } from "./RootStore"

export const routes = [
    {
        name: "home",
        pattern: "/",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {lastStore} = rootStore as RootStore

            lastStore.loadBooks()
        }
    },
    {
        name: "comix",
        pattern: "/comix/:comixCode",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {comixStore} = rootStore as RootStore
            const {comixCode} = toState.params

            comixStore.setGoToHome(fromState.routeName === "__initial__")
            comixStore.resetComix()
            comixStore.setComix(comixCode)
        }
    },
    {
        name: "publishers",
        pattern: "/publishers",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {publishersStore} = rootStore as RootStore

            publishersStore.loadPublishers()
        }
    },
    {
        name: "publisher",
        pattern: "/publishers/:id",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {publishersStore} = rootStore as RootStore
            const {id} = toState.params

            publishersStore.resetPublisher()
            publishersStore.setPublisher(id)
        }
    },
    {
        name: "series",
        pattern: "/series/:id",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const {rootStore} = routerStore.options
            const {seriesStore} = rootStore as RootStore
            const {id} = toState.params

            seriesStore.clearSeries()
            seriesStore.setSeries(id)
        }
    },
]