import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Link from "@material-ui/core/Link"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            padding: 8,
            marginBottom: 5,
        },
        header: {
            padding: 0,
        },
        inline: {
            display: "inline",
        },
        crumbs: {
            padding: theme.spacing(1),
        },
        link: {
            display: "flex",
        },
    }),
)

function PublishersPage() {
    const {publishersStore, routerStore} = useRootStore()
    const classes = useStyles()

    const handleClick = (id) => {
        routerStore.goTo("publisher", {
            params: {
                id: id
            }
        })
    }

    const handleClickHome = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        routerStore.goTo("home")
    }

    return (
        <React.Fragment>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" className={classes.crumbs}>
                <Link color="inherit" href="#" className={classes.link} onClick={handleClickHome}>
                    <Typography variant="caption" color={"textSecondary"}>online</Typography>
                    <Typography variant="button" color={"textPrimary"}>Comix</Typography>
                </Link>
            </Breadcrumbs>


            {publishersStore.publishers.map((publisher, i) => (
                <Card key={i} className={classes.root} onClick={() => handleClick(publisher.alias)}>
                    <CardHeader
                        className={classes.header}
                        title={publisher.name}/>
                </Card>
            ))}

        </React.Fragment>
    )
}

export default observer(PublishersPage)