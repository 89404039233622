import API from "../components/API"

export interface PublisherItem {
    id: number
    name: string
    alias: string
}

export async function GetPublishers() {

    return await API.get("/get-publishers")
}
