import { Books, GetLast } from "../services/GetLast"
import { makeAutoObservable, observable } from "mobx"
import { RootStore } from "./RootStore"

export class LastStore {
    rootStore: RootStore
    books: Books[]

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            books: observable,
        })

        this.rootStore = rootStore
    }

    loadBooks = async () => {
        GetLast().then(response => {
            this.books = response.data as Books[]
        })
    }
}

