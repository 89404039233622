import { RouterStore, createRouterState } from "mobx-state-router"
import { ComixStore } from "./ComixStore"
import { LastStore } from "./LastStore"
import { PublishersStore } from "./PublishersStore"
import { SeriesStore } from "./SeriesStore"
import { autorun } from "mobx"
import { routes } from "./routes"

const notFound = createRouterState("notFound")

export class RootStore {
    public comixStore = new ComixStore(this)
    public publishersStore = new PublishersStore(this)
    public seriesStore = new SeriesStore(this)
    public lastStore = new LastStore(this)

    public routerStore = new RouterStore(routes, notFound, {
        rootStore: this
    })

    constructor () {
        autorun(() => console.log("Entered route: " + this.routerStore.routerState.routeName))
    }
}
