import API from "../components/API"

export interface Books {
    id: string
    serial: string
    cover: string
    number: number
}

export async function GetLast() {

    return await API.get("/get-last")
}
