import { Block, ComixData, GetComix } from "../services/GetComix"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"

export class ComixStore {
    rootStore: RootStore
    comixID: string
    comixData = [] as ComixData[]
    currentPage = {} as ComixData
    currentPageID: number
    currentBlock = {} as Block
    currentBlockID: number
    images = []
    loaderImages = 0
    goToHome = false

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            comixID: observable,
            currentPageID: observable,
            currentBlockID: observable,
            images: observable,
            loaderImages: observable,
            goToHome: observable,
        })

        this.rootStore = rootStore

        reaction(() => this.comixID, () => {
            if (this.comixID) {
                this.loadComix()
            }
        })
        reaction(() => this.currentPageID, () => {
            if (this.currentPageID !== undefined) {
                this.setPage()
            }
        })
        reaction(() => this.currentBlockID, () => {
            if (this.currentBlockID !== undefined) {
                this.setBlock()
            }
        })
    }

    setComix = (id:string) => {
        this.comixID = id
    }

    setGoToHome = (home: boolean) => {
        this.goToHome = home
    }

    resetComix = () => {
        this.currentPageID = undefined
        this.currentBlockID = undefined
        this.images = []
        this.loaderImages = 0
        this.comixID = undefined
    }

    setPage = () => {
        this.currentPage = this.comixData[this.currentPageID]
        if (this.currentPage.p.length > 0) {
            this.setBlock()
        }
    }

    setBlock = () => {
        this.currentBlock = this.comixData[this.currentPageID].p[this.currentBlockID]
    }

    nextBlock = () => {
        let s = this.comixData[this.currentPageID].p.length
        if (this.currentBlockID < s - 1) {
            this.currentBlockID++
        } else {
            this.nextPage()
        }

        console.log(this.currentBlock)
    }

    prevBlock = () => {
        if (this.currentBlockID > 0) {
            this.currentBlockID--
        } else {
            this.prevPage()
        }

        console.log(this.currentBlock)
    }

    nextPage = () => {
        let s = this.comixData.length
        if (this.currentPageID < s - 1) {
            this.currentBlockID = 0
            this.currentPageID++
        }
    }

    prevPage = () => {
        if (this.currentPageID > 0) {
            this.currentBlockID = this.comixData[this.currentPageID-1].p.length - 1
            this.currentPageID--
        }
    }

    loadComix = async () => {
        GetComix(this.comixID).then(response => {
            let ns = response.data as ComixData[]
            ns.forEach((page, i) => {
                page.p.forEach((block, b) => {
                    block.isFirst = (i === 0 && b === 0)
                    block.isLast = (i === ns.length - 1 &&  b === page.p.length - 1)

                    if (typeof page.p[b+1] != "undefined") {
                        page.p[b].viewBoxNext = page.p[b+1].viewBox
                        page.p[b].patchNext = page.p[b+1].patch
                        page.p[b].nextPage = false
                    } else if (typeof ns[i+1] != "undefined") {
                        page.p[b].viewBoxNext = ns[i+1].p[0].viewBox
                        page.p[b].patchNext = ns[i+1].p[0].patch
                        page.p[b].nextPage = true
                    } else {
                        page.p[b].viewBoxNext = "0 0 0 0"
                        page.p[b].patchNext = "0,0 0,0 0,0 0,0"
                        page.p[b].nextPage = false
                    }

                    if (typeof page.p[b-1] != "undefined") {
                        page.p[b].viewBoxPrev = page.p[b-1].viewBox
                        page.p[b].patchPrev = page.p[b-1].patch
                        page.p[b].prevPage = false
                    } else if (typeof ns[i-1] != "undefined") {
                        page.p[b].viewBoxPrev = ns[i-1].p[ns[i-1].p.length-1].viewBox
                        page.p[b].patchPrev = ns[i-1].p[ns[i-1].p.length-1].patch
                        page.p[b].prevPage = true
                    } else {
                        page.p[b].viewBoxPrev = ns[0].p[0].viewBox
                        page.p[b].patchPrev = ns[0].p[0].patch
                        page.p[b].prevPage = false
                    }
                })
            })
            console.log(ns)
            this.comixData = ns
            this.currentPageID = 0
            this.currentBlockID = 0
            this.comixData.forEach(page => {
                let img = new Image()
                img.src = page.img
                this.images.push(img)
                img.onload = () => {
                    this.loaderImages = this.loaderImages + 1
                }
            })
        })
    }
}

