import { GetPublisher, Publisher } from "../services/GetPublisher"
import { GetPublishers, PublisherItem } from "../services/GetPublishers"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"

export class PublishersStore {
    rootStore: RootStore
    publishers = [] as PublisherItem[]
    publisher: Publisher
    publisherId: string

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            publishers: observable
        })

        this.rootStore = rootStore

        reaction(() => this.publisherId, () => {
            if (this.publisherId) {
                this.loadPublisher()
            }
        })
    }
    loadPublishers = async () => {
        GetPublishers().then(response => {
            this.publishers = response.data as PublisherItem[]
        })
    }

    resetPublisher = () => {
        this.publisher = undefined
        this.publisherId = undefined
    }
    setPublisher = (id: string) => {
        this.publisherId = id
    }

    loadPublisher = async () => {
        GetPublisher(this.publisherId).then(response => {
            this.publisher = response.data as Publisher
        })
    }
}

