import { GetSeries, Series } from "../services/GetSeries"
import { makeAutoObservable, observable, reaction } from "mobx"
import { RootStore } from "./RootStore"

export class SeriesStore {
    rootStore: RootStore
    series: Series
    seriesId: string

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {
            series: observable,
            seriesId: observable
        })

        this.rootStore = rootStore

        reaction(() => this.seriesId, () => {
            if (this.seriesId) {
                this.loadSeries()
            }
        })
    }

    setSeries = (id: string) => {
        this.seriesId = id
    }

    clearSeries = () => {
        this.series = undefined
        this.seriesId = undefined
    }

    loadSeries = async () => {
        GetSeries(this.seriesId).then(response => {
            this.series = response.data as Series
        })
    }
}

