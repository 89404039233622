import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import Divider from "@material-ui/core/Divider"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            alignItems: "flex-start",
            padding: theme.spacing(1),
        },
        inline: {
            display: "inline",
        },
        media: {
            height: 140,
        },
        crumbs: {
            padding: theme.spacing(1),
        },
        link: {
            display: "flex",
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        img: {
            maxWidth: theme.spacing(3),
        },
    }),
)

function PublisherPage() {
    const {publishersStore, routerStore} = useRootStore()
    const classes = useStyles()

    const handleClick = (id) => {
        routerStore.goTo("series", {
            params: {
                id: id
            }
        })
    }
    const handleClickPublishers = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        routerStore.goTo("publishers")
    }
    const handleClickHome = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        routerStore.goTo("home")
    }

    return (
        <React.Fragment>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" className={classes.crumbs}>
                <Link color="inherit" href="#" className={classes.link} onClick={handleClickHome}>
                    <Typography variant="caption" color={"textSecondary"}>online</Typography>
                    <Typography variant="button" color={"textPrimary"}>Comix</Typography>
                </Link>
                <Link color="inherit" href="#" className={classes.link} onClick={handleClickPublishers}>
                    Издательства
                </Link>
            </Breadcrumbs>

            {publishersStore.publisher && <React.Fragment>
                <Card>
                    <CardHeader
                        className={classes.root}
                        title= {publishersStore.publisher.vendor.name}/>
                </Card>
                <List className={classes.root}>
                    {publishersStore.publisher.series.map((series, i) => (
                        <React.Fragment key={i}>
                            <ListItem>
                                <ListItemIcon>
                                    {series.cover && series.cover !== "" &&
                                        <img src={series.cover} alt={""} className={classes.img}/>
                                    }
                                    {(!series.cover || series.cover === "") &&
                                        <LibraryBooksIcon color="disabled"/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={series.name}
                                    onClick={() => handleClick(series.id)}
                                />
                            </ListItem>
                            <Divider component="li"/>
                        </React.Fragment>
                    ))}
                </List>
            </React.Fragment>}
        </React.Fragment>
    )
}

export default observer(PublisherPage)