import ComixPage from "../pages/ComixPage"
import HomePage from "../pages/HomePage"
import PublisherPage from "../pages/PublisherPage"
import PublishersPage from "../pages/PublishersPage"
import React from "react"
import SeriesPage from "../pages/SeriesPage"

export const viewMap = {
    home: <HomePage />,
    comix: <ComixPage />,
    publishers: <PublishersPage/>,
    publisher: <PublisherPage/>,
    series: <SeriesPage/>,
}
