import { CircularProgress, Theme, createStyles, makeStyles } from "@material-ui/core"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import React from "react"
import SkipNextIcon from "@material-ui/icons/SkipNext"
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious"
import SvgComponent from "../../components/SvgComponent"
import UndoIcon from "@material-ui/icons/Undo"
import { browserHistory } from "mobx-state-router"
import { observer } from "mobx-react"
import { useAnimation } from "framer-motion"
import { useRootStore } from "../../contexts"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        box: {
            position: "absolute",
            top: "calc(50% - 28px)",
            transform: "translate(0, -50%)",
            width: "100%",
        },
        panel: {
            position: "absolute",
            bottom: 0,
            width: "100%"
        }
    }))

function ComixPage() {
    const {comixStore, routerStore} = useRootStore()
    const page = comixStore.currentPage
    const block = comixStore.currentBlock
    const image = comixStore.images ? comixStore.images[comixStore.currentPageID] : null
    const classes = useStyles()
    const controls = useAnimation()
    const controls2 = useAnimation()
    const controls3 = useAnimation()

    if (!comixStore.comixData || !block || !block.viewBox) {
        return null
    }
    const loading = comixStore.comixData.length === comixStore.loaderImages

    const handleBackClick = () => {
        if (comixStore.goToHome) {
            routerStore.goTo("home")
        } else {
            browserHistory.goBack()
        }
    }

    const handleClickNext = () => {
        let delay = 0
        if (block.nextPage) {
            delay = 0.5
            controls3.start({
                opacity: 0,
                transition: {
                    delay: 0,
                    duration: 0.5,
                    ease: "easeInOut",
                    elapsed: -1.5,
                }
            })
            setTimeout(function () {
                comixStore.nextBlock()
                controls3.start({
                    opacity: 1,
                    transition: {
                        delay: 0,
                        duration: 0.5,
                        ease: "easeInOut",
                        elapsed: -1.5,
                    }
                })
            }, 1000)

        } else {
            comixStore.nextBlock()
        }

        controls.start({
            viewBox: [
                block.viewBox,
                block.viewBoxNext,
            ],
            transition: {
                delay: delay,
                duration: delay ? 0 : 1,
                ease: "easeInOut",
                elapsed: -1.5
            }
        })
        controls2.start({
            points: [
                block.patch,
                block.patchNext,
            ],
            transition: {
                delay: delay,
                duration: delay ? 0 : 1,
                ease: "easeInOut",
                elapsed: -1.5
            }
        })
    }
    const handleClickPrev = () => {
        let delay = 0
        if (block.prevPage) {
            delay = 0.5
            controls3.start({
                opacity: 0,
                transition: {
                    delay: 0,
                    duration: 0.5,
                    ease: "easeInOut",
                    elapsed: -1.5,
                }
            })
            setTimeout(function () {
                comixStore.prevBlock()
                controls3.start({
                    opacity: 1,
                    transition: {
                        delay: 0,
                        duration: 0.5,
                        ease: "easeInOut",
                        elapsed: -1.5,
                    }
                })
            }, 1000)

        } else {
            comixStore.prevBlock()
        }

        controls.start({
            viewBox: [
                block.viewBox,
                block.viewBoxPrev,
            ],
            transition: {
                delay: delay,
                duration: delay ? 0 : 1,
                ease: "easeInOut",
                elapsed: -1.5
            }
        })
        controls2.start({
            points: [
                block.patch,
                block.patchPrev,
            ],
            transition: {
                delay: delay,
                duration: delay ? 0 : 1,
                ease: "easeInOut",
                elapsed: -1.5
            }
        })
    }

    return (
        <React.Fragment>
            <div className={classes.box}>
                {!loading && <div className={classes.flex}>
                    <CircularProgress size={80} thickness={1.2}/>
                </div>}
                {loading && <SvgComponent
                    patch={block.patch}
                    viewBox={block.viewBox}
                    url={page.img}
                    width={page.width}
                    height={page.height}
                    onNext={handleClickNext}
                    onPrev={handleClickPrev}
                    image={image}
                    controls={controls}
                    controls2={controls2}
                    controls3={controls3}
                />}
            </div>

            <BottomNavigation
                showLabels
                className={classes.panel}
            >
                <BottomNavigationAction
                    disabled={block.isFirst}
                    label="Назад"
                    icon={<SkipPreviousIcon/>}
                    onClick={handleClickPrev}/>
                <BottomNavigationAction label="Вернуться" icon={<UndoIcon/>} onClick={handleBackClick}/>
                <BottomNavigationAction
                    disabled={block.isLast}
                    label="Вперед"
                    icon={<SkipNextIcon/>}
                    onClick={handleClickNext}/>
            </BottomNavigation>
        </React.Fragment>
    )
}

export default observer(ComixPage)
